<!-- eslint-disable vue/singleline-html-element-content-newline -->
<!-- eslint-disable vue/max-attributes-per-line -->
<template>
  <div>
    <b-card
      no-body
      class="coupon-card"
    >
      <b-card-header>
        <div
          class="d-flex flex-wrap justify-content-between align-items-center w-100 mb-2"
          style="gap: 8px;"
        >
          <h4 class="mb-0">
            {{ $t('Create levels') }}
          </h4>
        </div>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col
            md="6"
          >
            <b-row>
              <b-col
                md="12"
              >
                <b-form-group
                  :label="$t('Levels name')"
                >
                  <b-form-input />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                md="6"
              >
                <b-form-group
                  :label="$t('Doanh thu')"
                >
                  <b-form-input />
                </b-form-group>
              </b-col>
              <b-col
                md="6"
              >
                <b-form-group
                  :label="$t('Chiết khấu')"
                >
                  <b-form-input />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                md="12"
              >
                <b-button
                  variant="primary"
                >
                  {{ $t('Save') }}
                </b-button>
              </b-col>
            </b-row>
          </b-col>
          <b-col
            md="6"
          >
            <div class="note-levels">
              <feather-icon icon="ShieldIcon" size="32" />
              <div class="content-block">
                <div class="title">Khi người giới thiệu đạt được doanh thu tương ứng sẽ được tự động nâng cấp độ tài khoản, lúc này cách tính hoa hồng sẽ bằng</div>
                <strong>
                  Hoa hồng nhận được = % chiết khấu mặc định + % chiết khấu cấp độ tài khoản
                </strong>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BButton,
  BRow,
  BCol,
  BCard,
  BCardHeader,
  // BCardTitle,
  BCardBody,
  VBTooltip,
  BFormInput,
  BFormGroup,
  // BBadge,
  // BDropdown,
  // BDropdownItem,
  // BFormCheckbox,
} from 'bootstrap-vue'
// import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Request from '@/plugins/service/index'
import general from '@/mixins/index'

export default {
  name: 'DiscountList',
  components: {
    BButton,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    // BCardTitle,
    BCardBody,
    // flatPickr,
    BFormInput,
    BFormGroup,
    // BBadge,
    // vSelect,
    // BDropdown,
    // BDropdownItem,
    // LanguageSelect,
    // BFormCheckbox,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mixins: [general],
  data() {
    return {
      discountTypes: [
        {
          value: 1,
          text: 'Theo giá trị đơn hàng',
        },
        {
          value: 2,
          text: 'Danh mục sản phẩm',
        },
        {
          value: 3,
          text: 'Sản phẩm',
        },
      ],
      discountTypeSelected: 1,
      items: [
        {
          id: 1,
          name: 'Áo thun bao lỗ cực mát',
          avatar: 'https://beta-fileapi.busodevelopers.com//platform/101/2021/11/platform_products/desktop/7ec3869e91252f21fd8f24c379f209f4.png',
          selected: false,
          indeterminate: false,
          attribute: [
            {
              parent_id: 1,
              id: 11,
              name: 'Xanh/ S/ Thun',
              stock: 5,
              sku: 'A0038687',
              barcode: 'A0038687',
              selected: false,
            },
            {
              parent_id: 1,
              id: 12,
              name: 'Đen/ S/ Thun',
              stock: 5,
              sku: 'A00386877',
              barcode: 'A00386877',
              selected: false,
            },
          ],
        },
        {
          id: 2,
          name: 'Áo Khoác cực mát',
          avatar: 'https://beta-fileapi.busodevelopers.com//platform/101/2021/11/platform_products/desktop/7ec3869e91252f21fd8f24c379f209f4.png',
          selected: false,
          indeterminate: false,
          attribute: [
            {
              parent_id: 2,
              id: 21,
              name: 'Đen/ XL/ Kaki',
              stock: 1,
              sku: 'A00386878',
              barcode: 'A00386878',
              selected: false,
            },
          ],
        },
      ],
      pageLength: 10,
      searchTerm: '',
      selectedArr: [],
      fields: [
        { field: 'title', label: 'Products', sortable: true },
        { field: 'price', label: 'Price', sortable: false },
        {
          field: 'stock', label: 'inventory', sortable: false, tdClass: 'text-center',
        },
        {
          field: 'discount_percent', label: '% discount', sortable: false, tdClass: 'text-center',
        },
        {
          field: 'actions', label: 'Actions', sortable: false, tdClass: 'text-center',
        },
      ],
      fields2: [
        { field: 'label', label: 'Category', sortable: true },
        {
          field: 'discount_percent', label: '% discount', sortable: false, tdClass: 'text-center',
        },
        {
          field: 'actions', label: 'Actions', sortable: false, tdClass: 'text-center',
        },
      ],
      selectedProducts: [
        {
          id: 1,
          name: 'Áo thun bao lỗ cực mát',
          avatar: 'https://beta-fileapi.busodevelopers.com//platform/101/2021/11/platform_products/desktop/7ec3869e91252f21fd8f24c379f209f4.png',
          selected: false,
          indeterminate: false,
          price: 150000,
          stock: 95,
          discount_percent: 0,
        },
        {
          id: 2,
          name: 'Nước mắm Nam Ngư',
          avatar: 'https://beta-fileapi.busodevelopers.com//upload/286/2022/1/products_media/desktop/7007da574fb0b6cf6a8f522f161eec82.jpeg',
          selected: false,
          indeterminate: false,
          price: 175000,
          stock: 65,
          discount_percent: 0,
        },
      ],
      productCategories: [],
      productCategorySelected: [],
      // productCategorySelectedList: [],
      productCategoriesSelectedCount: 0,
      applyOn: {
        percent: 0,
      },
    }
  },
  computed: {
    productCategorySelectedList() {
      const result = []
      if (this.productCategorySelected.length === 0) return result
      // eslint-disable-next-line array-callback-return
      this.productCategorySelected.map(item => {
        if (item) {
          result.push(this.productCategories.find(cate => cate.id === item))
        }
      })
      // eslint-disable-next-line consistent-return
      return result
    },
  },
  mounted() {
    this.loadCategoryList()
  },
  methods: {
    handleSelectItem(val) {
      // eslint-disable-next-line
      // find index of attribute in arr
      const parent = this.items.findIndex(x => x.id === val.parent_id)
      let d = 0
      // eslint-disable-next-line array-callback-return
      this.items[parent].attribute.map(x => {
        if (x.selected) {
          // eslint-disable-next-line no-plusplus
          d++
        }
      })
      if (d > 0) this.items[parent].indeterminate = true
      if (d === 0) {
        this.items[parent].selected = false
        this.items[parent].indeterminate = false
      }
      if (d === this.items[parent].attribute.length) {
        this.items[parent].selected = true
        this.items[parent].indeterminate = false
      }
    },
    handleSelectAll(val) {
      const index = this.items.find(x => x.id === val.id)
      // eslint-disable-next-line array-callback-return
      index.attribute.map(x => {
        // eslint-disable-next-line no-param-reassign
        x.selected = val.selected
      })
      this.saveTempArr()
    },
    saveTempArr(item, option = {}) {
      // eslint-disable-next-line array-callback-return
      if (option.all) {
        this.selectedArr.map(x => x.id.include(item.id))
      }
    },
    handlerRemove(id) {
      this.selectedProducts.splice(this.selectedProducts.findIndex(item => item.id === id), 1)
    },
    async loadCategoryList() {
      try {
        const siteId = JSON.parse(localStorage.getItem('siteID')).id
        const result = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/category/tree?site_id=${siteId}`,
        )
        if (result.status === 200) {
          if (result.data.status) {
            this.productCategories = []
            const res = result.data.data
            // eslint-disable-next-line array-callback-return
            res.map(x => {
              const item = {
                id: x.id_category,
                label: x.name,
                slug: x.slug,
                idx: x.id_category,
                children: [],
              }
              if (x.children && x.children.length > 0) {
                // eslint-disable-next-line array-callback-return
                x.children.map(y => {
                  const xitem = {
                    id: y.id_category,
                    label: y.name,
                    slug: y.slug,
                    idx: y.id_category,
                    children: [],
                    parent_id: x.id_category,
                  }
                  if (y.children && y.children.length > 0) {
                    // eslint-disable-next-line array-callback-return
                    y.children.map(z => {
                      const zitem = {
                        id: z.id_category,
                        label: z.name,
                        slug: z.slug,
                        idx: z.id_category,
                        parent_id: y.id_category,
                        root_id: x.id_category,
                      }
                      xitem.children.push(zitem)
                    })
                  }
                  item.children.push(xitem)
                })
              }
              this.productCategories.push(item)
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(result.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    repeatCategory(elm) {
      this[elm].push(null)
    },
    removeCategory(elm, index) {
      this[elm].splice(index, 1)
    },
    // onTreeselectSelect(data) {
    // },
    // advanceApply() {
    // },
    // advanceDelete() {
    // },
    async loadSelectedCategoryList() {
      try {
        const siteId = JSON.parse(localStorage.getItem('siteID')).id
        const result = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/category/tree?site_id=${siteId}`,
        )
        if (result.status === 200) {
          if (result.data.status) {
            this.productCategorySelectedList = []
            const res = result.data.data
            // eslint-disable-next-line array-callback-return
            res.map(x => {
              const item = {
                id: x.id_category,
                label: x.name,
                slug: x.slug,
                idx: x.id_category,
                children: [],
              }
              if (x.children && x.children.length > 0) {
                // eslint-disable-next-line array-callback-return
                x.children.map(y => {
                  const xitem = {
                    id: y.id_category,
                    label: y.name,
                    slug: y.slug,
                    idx: y.id_category,
                    children: [],
                    parent_id: x.id_category,
                  }
                  if (y.children && y.children.length > 0) {
                    // eslint-disable-next-line array-callback-return
                    y.children.map(z => {
                      const zitem = {
                        id: z.id_category,
                        label: z.name,
                        slug: z.slug,
                        idx: z.id_category,
                        parent_id: y.id_category,
                        root_id: x.id_category,
                      }
                      xitem.children.push(zitem)
                    })
                  }
                  item.children.push(xitem)
                })
              }
              this.productCategorySelectedList.push(item)
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(result.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
  },
}
</script>

<style src="@riophae/vue-treeselect/dist/vue-treeselect.min.css"></style>
<style lang="scss">
.note-levels {
  display: flex;
  padding: 16px;
  background-color: #f8f8f8;
  border-radius: 8px;
  svg {
    margin-right: 16px;
    color: #28c76f;
  }
}
.custom-good-table .vgt-table {
  font-size: 14px;

  thead th {
    vertical-align: middle;
    text-align: center;
    padding-right: 0.75em;
    white-space: nowrap;
  }
  td {
    vertical-align: middle;
  }
}
.custom-good-table-ctrl-border{
  .vgt-table{
    border: 1px solid #fff !important;
  }
  td{
    padding: 0.75rem !important;

    &:first-child {
      padding-left: 1.5rem !important;
    }
    &:last-child {
      padding-right: 1.5rem !important;
    }
  }
  thead{
    background-color: #f3f2f7
  }
}
.v-select-custom {
  .vs {
    &__selected-options {
      max-width: calc(100% - 24px);
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      flex-wrap: nowrap;
    }
    &__search {
      &::placeholder {
        color: var(--gray)
      }
    }
  }
}
.fade-browse {
  border: 1px dashed var(--primary);
  color: var(--primary);
  border-radius: 4px;
  padding: 8px 12px;
  text-align: center;
  position: relative;
  cursor: pointer;

  input:focus,
  button:focus {
    outline: unset;
    box-shadow: unset;
    border: unset;
  }
  .btn-add {
    position: absolute;
    z-index: 1;
  }
  .img-box {
    position: relative;
    z-index: 2;
    opacity: 0;
  }
}
</style>
